import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import Button from '@objects/button'
import TextField from '@objects/formFields/textField'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import SurveyContext from '@providers/surveyProvider'

import loginSurvey from '@api/loginSurvey'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginBottom: theme.spacing(4),
  },
  gridItemContent: { paddingLeft: '20px' },
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

function SurveyLoginForm({ handleSubmit }) {
  const classes = useStyles()
  const { setPassword } = useContext(SurveyContext)
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const validationSchema = Yup.object({
    password: Yup.string().required('E-Mail Adresse wird benötigt.'),
  })

  const initialValues = {
    password: '',
  }

  function _handleSubmit(values, { setSubmitting }) {
    loginSurvey(values)
      .then((response) => {
        if (response.status === 202) {
          handleSubmit(), setPassword(values)
        } else {
          toggleOverlay(true, 'surveyLoginError', 'default', {
            trackingID: 'surveyLoginError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch((err, test) => {
        toggleOverlay(true, 'surveyLoginError', 'default', {
          trackingID: 'surveyLoginError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form id="newsletter">
          <Grid container spacing={5} justify="center">
            <h2>Bitte geben Sie Ihre E-Mail Adresse ein.</h2>
            <Grid item xs={12} md={6}>
              <TextField
                id="pw-input"
                data-testid="pw-input"
                fullWidth
                variant="outlined"
                name="password"
                label="E-Mail Adresse"
                type="input"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <Button
                className={classes.button}
                disabled={isSubmitting}
                formSubmit
              >
                Senden
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

SurveyLoginForm.propTypes = {
  handleSubmit: PropTypes.func,
}

export default SurveyLoginForm
