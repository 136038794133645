import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'

import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
  },
}))

function SurveyThanks({ handleSubmit }) {
  const classes = useStyles()

  return (
    <Grid container spacing={5} justify="center">
      <h3>Vielen Dank für Ihre Teilnahme an der Evaluation.</h3>
      <Grid item xs={12}>
        <Button className={classes.button} onClick={handleSubmit}>
          Zur Startseite
        </Button>
      </Grid>
    </Grid>
  )
}

SurveyThanks.propTypes = {
  handleSubmit: PropTypes.func,
}

export default SurveyThanks
