import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useField } from 'formik'

import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import FormHelperText from './helperText'

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  textFieldWrapper: {
    flexGrow: 1,
  },
}))

const StyledInputLabel = withStyles((theme) => ({
  root: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 3, 5),
    color: 'inherit',
  },
}))(InputLabel)

function TextFieldInput(props) {
  const { label, infoText, id, name, type, textInputName, ...other } = props
  const [field, meta] = useField(props)

  return (
    <>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <TextField
        id={id}
        type={type}
        error={meta.touched && !!meta.error}
        {...field}
        {...other}
        onBlur={null}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText spaced type="error">
          {meta.error}
        </FormHelperText>
      ) : null}
    </>
  )
}

TextFieldInput.propTypes = {
  label: PropTypes.string,
  infoText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  textInputName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email']),
}

TextFieldInput.defaultProps = {
  type: 'text',
}

function renderOptions(options, disabled, textInputName, fieldValue, ...props) {
  const classes = useStyles()

  return options.map((option) => (
    <div className={classes.radioGroup}>
      <FormControlLabel
        key={option}
        value={disabled ? null : option}
        control={<Radio />}
        disabled={disabled}
        label={option}
      />
      {option === 'Sonstige:' ? (
        <>
          <div
            className={clsx(classes.textFieldWrapper, 'MuiInputBase-fullWidth')}
          >
            <TextFieldInput
              id="textInputId"
              name={textInputName}
              fullWidth
              disabled={fieldValue !== 'Sonstige:' ? true : false}
            />
          </div>
        </>
      ) : option === 'Andere Funktion' ? (
        <>
          <div className={clsx(classes.textFieldWrapper)}>
            <TextFieldInput
              id="textInputId"
              name={textInputName}
              fullWidth
              disabled={fieldValue !== 'Andere Funktion' ? true : false}
            />
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  ))
}
renderOptions.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isArray: PropTypes.bool,
  infoText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  textInputName: PropTypes.string,
}

function FRadioGroup({
  id,
  field,
  form: { touched, errors },
  name,
  options,
  children,
  disabled,
  textInputName,
  ...props
}) {
  const fieldName = name || field.name
  const fieldValue = field.value
  const { label } = props

  return (
    <React.Fragment>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <RadioGroup id={id} {...field} {...props} name={fieldName}>
        {options
          ? renderOptions(options, disabled, textInputName, fieldValue)
          : children}
      </RadioGroup>

      {touched[fieldName] && errors[fieldName] && (
        <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
          {errors[fieldName]}
        </span>
      )}
    </React.Fragment>
  )
}

FRadioGroup.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isArray: PropTypes.bool,
  hasTextfield: PropTypes.bool,
  textInputName: PropTypes.string,
}

export default FRadioGroup
