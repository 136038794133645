import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'

import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

import saveSurvey from '@api/saveSurvey'

import ToggleComponentContext from '@providers/toggleComponentProvider'
import SurveyContext from '@providers/surveyProvider'

import Button from '@objects/button'
import TextField from '@objects/formFields/textField'
import FRadioGroup from '@objects/formFields/radioGroup'
import RichTextEditor from '@objects/formFields/richtext'
import CheckboxGroup from '@objects/formFields/checkboxGroup'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    marginBottom: theme.spacing(4),
  },
  gridItemContent: { paddingLeft: '20px' },
  button: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    display: 'inherit',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

const StyledInputLabel = withStyles((theme) => ({
  root: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 3, 0),
    color: 'inherit',
  },
}))(InputLabel)

function SurveyForm({ handleSubmit }) {
  const intl = useIntl()
  const { password } = useContext(SurveyContext)
  const classes = useStyles()
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const validationSchema = Yup.object().shape({
    angabePerson: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    angabePersonAndereFunktion: Yup.string(),
    einwohner: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    stadt: Yup.string(),
    aufmerksam: Yup.array().min(1, 'Bitte mindestens ein Feld auswählen.'),
    aufmerksamEmpfehlung: Yup.string().when('aufmerksam', (aufmerksam) => {
      if (aufmerksam.includes('aufmerksamEmpfehlung')) {
        return Yup.string()
          .oneOf([
            'Eines Bürgers, einer Bürgerin',
            'Einer*s kommunalen Amtsträgers / Amtsträgerin',
            'Sonstige:',
          ])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    aufmerksamEmpfehlungTextInput: Yup.string().when(
      ['aufmerksam', 'aufmerksamEmpfehlung'],
      (aufmerksam, aufmerksamEmpfehlung) => {
        if (
          aufmerksam.includes('aufmerksamEmpfehlung') &&
          aufmerksamEmpfehlung == 'Sonstige:'
        ) {
          return Yup.string().required(
            intl.formatMessage({ id: 'contact.error.required' })
          )
        }
        return Yup.string()
      }
    ),

    kanal: Yup.array().min(1, 'Bitte mindestens ein Feld auswählen.'),

    kanalSonstigesTextInput: Yup.string().when('kanal', (kanal) => {
      if (kanal.includes('kanalSonstiges')) {
        return Yup.string().required(
          intl.formatMessage({ id: 'contact.error.required' })
        )
      }
      return Yup.string()
    }),

    reaktion: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    zufriedenPlanung: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    zufriedenDurchfuehrung: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    initiativen: Yup.array().min(1, 'Bitte mindestens ein Feld auswählen.'),

    dialog: Yup.string().when('initiativen', (initiativen) => {
      if (initiativen.includes('dialog')) {
        return Yup.string()
          .oneOf(['Vor Ort', 'Digital'])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    kemf: Yup.string().when('initiativen', (initiativen) => {
      if (initiativen.includes('kemf')) {
        return Yup.string()
          .oneOf(['Vor Ort', 'Digital'])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    bnetza: Yup.string().when('initiativen', (initiativen) => {
      if (initiativen.includes('bnetza')) {
        return Yup.string()
          .oneOf(['Vor Ort', 'Digital'])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    betreiber: Yup.string().when('initiativen', (initiativen) => {
      if (initiativen.includes('betreiber')) {
        return Yup.string()
          .oneOf(['Vor Ort', 'Digital'])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    behoerden: Yup.string().when('initiativen', (initiativen) => {
      if (initiativen.includes('behoerden')) {
        return Yup.string()
          .oneOf(['Vor Ort', 'Digital'])
          .required(intl.formatMessage({ id: 'contact.error.required' }))
      }
      return Yup.string()
    }),

    initiativenBetreiberTextInput: Yup.string().when(
      'initiativen',
      (initiativen) => {
        if (initiativen.includes('betreiber')) {
          return Yup.string().required(
            intl.formatMessage({ id: 'contact.error.required' })
          )
        }
        return Yup.string()
      }
    ),

    initiativenBehoerdenTextInput: Yup.string().when(
      'initiativen',
      (initiativen) => {
        if (initiativen.includes('behoerden')) {
          return Yup.string().required(
            intl.formatMessage({ id: 'contact.error.required' })
          )
        }
        return Yup.string()
      }
    ),

    zufriedenMaterial: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    weiterempfehlung: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
    markierungKarte: Yup.string().required(
      intl.formatMessage({ id: 'contact.error.required' })
    ),
  })

  const initialValues = {
    angabePerson: '',
    angabePersonAndereFunktion: '',
    einwohner: '',
    stadt: '',
    aufmerksam: [],
    aufmerksamEmpfehlung: '',
    aufmerksamEmpfehlungTextInput: '',
    kanal: [],
    kanalSonstigesTextInput: '',
    reaktion: '',
    zufriedenPlanung: '',
    gefallenPlanung: '',
    zufriedenDurchfuehrung: '',
    gefallenDurchfuehrung: '',
    initiativen: [],
    dialog: '',
    kemf: '',
    bnetza: '',
    initiativenBetreiberTextInput: '',
    betreiber: '',
    initiativenBehoerdenTextInput: '',
    behoerden: '',
    zufriedenMaterial: '',
    informationen: '',
    schwerpunkte: '',
    weiterempfehlung: '',
    markierungKarte: '',
    anmerkungen: '',
  }

  function _handleSubmit(values, { resetForm, setSubmitting }) {
    const payload = {
      password: password.password,
      data: JSON.stringify(values),
    }
    saveSurvey(payload)
      .then((response) => {
        if (response.status === 202) {
          resetForm()
          handleSubmit()
        } else {
          toggleOverlay(true, 'surveySaveError', 'default', {
            trackingID: 'surveySaveError',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
        }
        setSubmitting(false)
      })
      .catch((err, test) => {
        toggleOverlay(true, 'surveySaveError', 'default', {
          trackingID: 'surveySaveError',
          width: 'sm',
          onAcceptance: () => toggleOverlay(false),
        })
        setSubmitting(false)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting }) => (
        <Form id="survey">
          <h2>Evaluation Bürgerdialoge: Deutschland spricht über 5G</h2>
          <Grid container spacing={5} justify="center">
            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="angabePerson">
                Angaben zur Person
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="angabePerson"
                name="angabePerson"
                options={[
                  'Ich bin Amtsträger*in',
                  'Ich bin Bürger*in',
                  'Ich bin Mitarbeiter*in in der Verwaltung',
                  'Andere Funktion',
                ]}
                component={FRadioGroup}
                textInputName="angabePersonAndereFunktion"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="einwohner">
                Wie viele Einwohner*innen hat Ihre Stadt oder Gemeinde?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="einwohner"
                name="einwohner"
                options={[
                  'Große Großstadt – über 500.000',
                  'Kleine Großstadt – 100.000-500.000',
                  'Größere Mittelstadt – 50.000-100.000',
                  'Kleine Mittelstadt – 20.000-50.000',
                  'Größere Kleinstadt – 10.000-20.000',
                  'Kleine Kleinstadt – 5.000-10.000',
                  'Landgemeinde – unter 5.000',
                ]}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="stadt">
                Möchten Sie uns sagen, um welche Stadt oder Gemeinde es sich
                handelt?
              </StyledInputLabel>
              <TextField
                className={classes.gridItemContent}
                id="stadt"
                name="stadt"
                fullWidth
                variant="outlined"
                placeholder="Geben Sie den Namen der Stadt ein"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="aufmerksam">
                Wie sind Sie auf die Dialoginitiative Dspricht5G aufmerksam
                geworden?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="aufmerksam-group"
                name="aufmerksam"
                options={[
                  {
                    name: 'Internetrecherche',
                    value: 'internetrecherche',
                    type: 'checkbox',
                  },
                  {
                    name: 'Website Dspricht5G',
                    value: 'website',
                    type: 'checkbox',
                  },
                  {
                    name: 'Facebook Dspricht5G',
                    value: 'facebook',
                    type: 'checkbox',
                  },
                  {
                    name: 'Twitter Dspricht5G',
                    value: 'twitter',
                    type: 'checkbox',
                  },
                  {
                    name: 'Instagram Dspricht5G',
                    value: 'instagram',
                    type: 'checkbox',
                  },
                  {
                    name: 'Youtube Dspricht5G',
                    value: 'youtube',
                    type: 'checkbox',
                  },
                  {
                    name: 'Empfehlung:',
                    value: 'aufmerksamEmpfehlung',
                    type: 'checkboxFieldwithRadioGroup',
                    options: [
                      'Eines Bürgers, einer Bürgerin',
                      'Einer*s kommunalen Amtsträgers / Amtsträgerin',
                      'Sonstige:',
                    ],
                  },
                ]}
                component={CheckboxGroup}
                textInputName="aufmerksamEmpfehlungTextInput"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="kanal-checkbox">
                Über welche Kanäle verlief die Kontaktaufnahme zum Dialogbüro?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="kanal-group"
                name="kanal"
                options={[
                  {
                    name: 'Mail',
                    value: 'kanalMail',
                    type: 'checkbox',
                  },
                  {
                    name: 'Telefon',
                    value: 'kanalTelefon',
                    type: 'checkbox',
                  },
                  {
                    name: 'Website',
                    value: 'kanalWebsite',
                    type: 'checkbox',
                  },
                  {
                    name: 'Sonstiges:',
                    value: 'kanalSonstiges',
                    type: 'checkboxTextfield',
                    textInputName: 'kanalSonstigesTextInput',
                  },
                ]}
                component={CheckboxGroup}
                textInputName="test"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="reaktion">
                Wie schnell wurde auf Ihre Anfrage reagiert?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="reaktion"
                name="reaktion"
                options={[
                  'Direkt',
                  'Nach mehreren Tagen',
                  'Nach mehreren Wochen',
                ]}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="zufrieden-planung">
                Wie zufrieden sind Sie mit der Planung der Veranstaltung?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="zufrieden-planung"
                name="zufriedenPlanung"
                options={[
                  'Sehr zufrieden',
                  'Zufrieden',
                  'Unzufrieden',
                  'Sehr unzufrieden',
                ]}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="gefallen-planung">
                Was hat Ihnen im Vorfeld der Veranstaltung gefallen oder Ihnen
                gefehlt?
              </StyledInputLabel>
              <RichTextEditor
                className={classes.gridItemContent}
                id="gefallen-planung"
                name="gefallenPlanung"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="zufrieden-durchfuehrung">
                Wie zufrieden sind Sie mit der Durchführung der Veranstaltung?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="zufrieden-durchfuehrung"
                name="zufriedenDurchfuehrung"
                options={[
                  'Sehr zufrieden',
                  'Zufrieden',
                  'Unzufrieden',
                  'Sehr unzufrieden',
                ]}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="gefallen-durchfuehrung">
                Was hat Ihnen an der Veranstaltung gefallen oder Ihnen gefehlt?
              </StyledInputLabel>
              <RichTextEditor
                className={classes.gridItemContent}
                id="gefallen-durchfuehrung"
                name="gefallenDurchfuehrung"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="initiativen-checkbox">
                Welche Initiativen bzw. Institutionen haben Ihre Veranstaltung
                vor Ort oder digital begleitet?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="initiativen-group"
                name="initiativen"
                options={[
                  {
                    name: 'Dialoginitiative Deutschland spricht über 5G',
                    value: 'dialog',
                    type: 'checkboxFieldwithRadioGroup',
                    options: ['Vor Ort', 'Digital'],
                  },
                  {
                    name: 'Kompetenzzentrum Elektromagnetische Felder KEMF',
                    value: 'kemf',
                    type: 'checkboxFieldwithRadioGroup',
                    options: ['Vor Ort', 'Digital'],
                  },
                  {
                    name: 'Bundesnetzagentur BNetzA',
                    value: 'bnetza',
                    type: 'checkboxFieldwithRadioGroup',
                    options: ['Vor Ort', 'Digital'],
                  },
                  {
                    name: 'Mobilfunknetzbetreiber:',
                    value: 'betreiber',
                    type: 'checkboxRadioGroupwithTextfield',
                    textInputName: 'initiativenBetreiberTextInput',
                    options: ['Vor Ort', 'Digital'],
                  },
                  {
                    name: 'Landesbehörden:',
                    value: 'behoerden',
                    type: 'checkboxRadioGroupwithTextfield',
                    textInputName: 'initiativenBehoerdenTextInput',
                    options: ['Vor Ort', 'Digital'],
                  },
                ]}
                component={CheckboxGroup}
                textInputName="textInput-group"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="zufrieden-material">
                Wie zufrieden sind Sie mit den Broschüren und Materialien der
                Dialoginitiative?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="zufrieden-material"
                name="zufriedenMaterial"
                options={[
                  'Sehr zufrieden',
                  'Zufrieden',
                  'Unzufrieden',
                  'Sehr unzufrieden',
                ]}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="informationen">
                Welche Inhalte fehlen Ihnen noch in den Informationsmaterialien?
              </StyledInputLabel>
              <RichTextEditor
                className={classes.gridItemContent}
                id="informationen"
                name="informationen"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="schwerpunkte">
                Welche inhaltlichen Schwerpunkte der Referierenden waren für Sie
                und die Bürger*innen besonders informativ und anschaulich?
              </StyledInputLabel>
              <RichTextEditor
                className={classes.gridItemContent}
                id="schwerpunkte"
                name="schwerpunkte"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="empfehlung">
                Würden Sie die Dialogbegleitung durch Deutschland spricht über
                5G weiterempfehlen?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="weiterempfehlung"
                name="weiterempfehlung"
                options={['Ja', 'Nein']}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="markierungKarte">
                Sind Sie damit einverstanden, dass die Veranstaltung auf einer
                Übersicht in Form einer Deutschlandkarte auf der Website der
                Dialoginitiative mit kurzer Zusammenfassung eingetragen wird?
              </StyledInputLabel>
              <Field
                className={classes.gridItemContent}
                id="markierungKarte"
                name="markierungKarte"
                options={['Ja', 'Nein']}
                component={FRadioGroup}
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <StyledInputLabel htmlFor="anmerkungen">
                Haben Sie noch weitere Anmerkungen oder Anregungen an das
                Dialogbüro?
              </StyledInputLabel>
              <RichTextEditor
                className={classes.gridItemContent}
                id="anmerkungen"
                name="anmerkungen"
              />
            </Grid>

            <Grid item xs={12} className={classes.gridItem}>
              <Button
                className={classes.button}
                disabled={isSubmitting}
                formSubmit
                icon="TextArrow"
              >
                Senden
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

SurveyForm.propTypes = {
  handleSubmit: PropTypes.func,
}

export default SurveyForm
