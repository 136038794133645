import React from 'react'
import PropTypes from 'prop-types'
import { Field, useField } from 'formik'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import FormHelperText from './helperText'
import Checkbox from './checkbox'

import FRadioGroup from '@objects/formFields/radioGroup'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    ...theme.typography.helpertext,
    color: theme.palette.text.hint,
    marginRight: 'auto',
    '&.error': {
      color: theme.palette.error.main,
    },
  },
  checkboxOption: {
    alignItems: 'flex-start',
    ...theme.typography.checkboxOption,
    '&.error': {
      color: theme.palette.error.main,
    },
    '& $label': {
      lineHeight: 1.3,
      padding: theme.spacing(3, 0),
    },
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
  },
  radioTextfieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  radioTextfield: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  radioWrapper: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#4b4b4b',
    fontSize: '14px',
  },
}))

const StyledInputLabel = withStyles((theme) => ({
  root: {
    ...theme.typography.label,
    padding: theme.spacing(0, 5, 3, 5),
    color: 'inherit',
  },
}))(InputLabel)

function TextFieldInput(props) {
  const { label, infoText, id, name, type, textInputName, ...other } = props
  const [field, meta] = useField(props)

  return (
    <>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <TextField
        id={id}
        type={type}
        error={meta.touched && !!meta.error}
        {...field}
        {...other}
        onBlur={null}
      />
      {infoText && (
        <FormHelperText spaced type="info">
          {infoText}
        </FormHelperText>
      )}
      {meta.touched && meta.error ? (
        <FormHelperText spaced type="error">
          {meta.error}
        </FormHelperText>
      ) : null}
    </>
  )
}

TextFieldInput.propTypes = {
  label: PropTypes.string,
  infoText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  textInputName: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email']),
}

TextFieldInput.defaultProps = {
  type: 'text',
}

function renderOptions(options, disabled, textInputName, field) {
  const classes = useStyles()

  function renderCheckboxType(option) {
    switch (option.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            classes={{
              root: classes.checkboxOption,
              label: classes.label,
            }}
            key={option.name}
            name="checkbox"
            value={option.value}
            control={<Checkbox id={option.name} name={field.name} />}
            disabled={disabled}
            label={option.name}
          />
        )
      case 'checkboxTextfield':
        return (
          <>
            <FormControlLabel
              classes={{
                root: classes.checkboxOption,
                label: classes.label,
              }}
              key={option.name}
              name="checkbox"
              value={option.value}
              control={<Checkbox id={option.name} name={field.name} />}
              disabled={disabled}
              label={option.name}
            />
            <TextFieldInput
              id={option.textInputName}
              name={option.textInputName}
              fullWidth
              disabled={!field.value.includes(option.value)}
            />
          </>
        )
      case 'checkboxFieldwithRadioGroup':
        return (
          <div className={classes.radioTextfieldWrapper}>
            <div className={classes.radioTextfield}>
              <FormControlLabel
                classes={{
                  root: classes.checkboxOption,
                  label: classes.label,
                }}
                key={option.name}
                name="checkbox"
                value={option.value}
                control={<Checkbox id={option.name} name={field.name} />}
                disabled={disabled}
                label={option.name}
              />
            </div>
            <Field
              disabled={!field.value.includes(option.value)}
              className={classes.radioWrapper}
              id={option.value}
              name={option.value}
              options={option.options}
              component={FRadioGroup}
              textInputName={textInputName}
            />
          </div>
        )
      case 'checkboxRadioGroupwithTextfield':
        return (
          <div className={classes.radioTextfieldWrapper}>
            <div className={classes.radioTextfield}>
              <FormControlLabel
                classes={{
                  root: classes.checkboxOption,
                  label: classes.label,
                }}
                key={option.name}
                name="checkbox"
                value={option.value}
                control={<Checkbox id={option.name} name={field.name} />}
                disabled={disabled}
                label={option.name}
              />
              <TextFieldInput
                id={option.textInputName}
                name={option.textInputName}
                fullWidth
                disabled={!field.value.includes(option.value)}
              />
            </div>
            <Field
              disabled={!field.value.includes(option.value)}
              className={classes.radioWrapper}
              id={option.value}
              name={option.value}
              options={option.options}
              component={FRadioGroup}
              textInputName={option.radioTextInputName}
            />
          </div>
        )
      default:
        return null
    }
  }
  return options.map((option) => (
    <div className={classes.radioGroup}>{renderCheckboxType(option)}</div>
  ))
}
renderOptions.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isArray: PropTypes.bool,
  infoText: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  textInputName: PropTypes.string,
}

function CheckboxGroup({
  id,
  field,
  form: { touched, errors },
  name,
  options,
  children,
  disabled,
  textInputName,
  ...props
}) {
  const fieldName = name || field.name
  const { label } = props

  return (
    <React.Fragment>
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <FormGroup id={id} {...field} {...props} name={fieldName}>
        {options
          ? renderOptions(options, disabled, textInputName, field)
          : children}
      </FormGroup>

      {touched[fieldName] && errors[fieldName] && (
        <span style={{ color: 'red', fontFamily: 'sans-serif' }}>
          {errors[fieldName]}
        </span>
      )}
    </React.Fragment>
  )
}

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  labelLink: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  isArray: PropTypes.bool,
  hasTextfield: PropTypes.bool,
  textInputName: PropTypes.string,
}

export default CheckboxGroup
