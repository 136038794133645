import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import SurveyLoginForm from '@components/forms/surveyLoginForm'
import SurveyForm from '@components/forms/surveyForm'
import SurveyThanks from '@components/forms/surveyThanks'
import Stage from '@components/stage'

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    padding: theme.spacing(0, 5, 5, 5),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 20, 20),
    },
    position: 'relative',
  },
  headline: {
    marginBottom: theme.spacing(17),
  },
  copy: {
    marginBottom: theme.spacing(12),
  },
}))

function Evaluation() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)

  function renderActiveFormStep() {
    switch (activeStep) {
      case 0:
        return (
          <SurveyLoginForm
            handleSubmit={() => {
              setActiveStep(1)
            }}
          />
        )
      case 1:
        return (
          <SurveyForm
            handleSubmit={() => {
              setActiveStep(2)
            }}
          />
        )
      case 2:
        return (
          <SurveyThanks
            handleSubmit={() => {
              navigate('/')
              // setActiveStep(0)
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title="Evaluation"
        ariaLabel="arialabel.evaluation"
      />
      <Container
        width="sm"
        className={classes.mainRoot}
        ariaLabel="arialabel.evaluation"
      >
        <div className={classes.contents}>{renderActiveFormStep()}</div>
      </Container>
    </>
  )
}

Evaluation.propTypes = {
  data: PropTypes.any,
}

export default Evaluation
